@import 'a3_variables.less';
@import 'a3_buttons.less';

/* Use of section as class so that we can apply to custom directives */
.section {display: block; }

/* Use of <section> instead of class */
section { padding:10px; width:100%; text-align:left; border-radius:2px; box-sizing:border-box; position:relative; border-width:1px; border-style:solid; }
section > header, .modal section > header { margin: 0 0 10px 0; padding: 0px 0px 10px 0; font-size: 13px; font-weight:normal; border-bottom-width:1px; border-bottom-style:solid; }
section:after { content:""; display:table; clear:both; }

/* main used for spacing */
section main { margin-top: 20px;}

/* area above sections */
section.above .tooltip + button { margin-left: 10px; }
section.above { padding:0; }
section.above .field-wrapper { margin-top:0; margin-bottom: 0; }

section.above h1, section.above h2, section.above h3, section.above h4, section.above h5, section.above h6 { margin-top:0; }

/* section behavior */
section:not(.ng-hide) + section, 
button + section, 
input[type=button] + section, 
input[type=submit] + section, 
section + .section, 
.section + .section, 
.section + section {
     
    margin-top: 20px; 
}
section + div:not(.tab-set) { margin-top:20px; }
[class*="col-"] section { margin-bottom: 20px; }
section header > i, section header > h1 > i, section header > h2 > i, section header > h3 > i, section header > h4 > i, section header > h5 > i, section header > h6 > i, section header person-heading > i, section header company-heading > i
{ margin-right:20px; font-family:"fontAwesome"; font-style:normal; }

/* section tables */
section.table-scroll { overflow-x:auto; }
section table { margin-bottom:0; }

/* section controls */
section footer button { padding:13px 20px; float:right; box-shadow:none; }

/* bootstrap-switch */
section.above .bootstrap-switch { margin-left:10px; }
section .bootstrap-switch i { margin-right: 0; }

/* buttons */
section ~ button, section ~ input[type=submit] { margin:20px 0 20px 5px; }
section table .field-wrapper {margin-bottom:0;}

section h1, section h2, section h3, section h4, section h5, section h6 { margin-top: 20px; }
section header h1, section header h2, section header h3, section header h4, section header h5, section header h6 { margin-top: 10px; }

section header a {text-decoration: underline; font-weight: normal; }
section.above.text-right button + button { margin-right:10px; }

/* Collapsable Sections */
section.collapsed header { margin: 0; padding: 0; border-bottom: 0; }
section header .expander { padding: 0 5px; }

/* text */
section > p { text-align:left; }

// field wrappers that are floated next to a heading inside of a section
section h1[class*="col-"] + .bootstrap-switch-wrapper[class*="col-"],
section h2[class*="col-"] + .bootstrap-switch-wrapper[class*="col-"],
section h3[class*="col-"] + .bootstrap-switch-wrapper[class*="col-"],
section h4[class*="col-"] + .bootstrap-switch-wrapper[class*="col-"],
section h5[class*="col-"] + .bootstrap-switch-wrapper[class*="col-"],
section h6[class*="col-"] + .bootstrap-switch-wrapper[class*="col-"] {margin-top:15px;}

/* images */
section img { max-width: 100%; }
section .profile-image {max-height: 200px; max-width:200px;}

/* release notes sections */
.release-left  { width:23%; height:100%; margin-right:2%; float:left; }
.release-right  { width:75%; float:left; margin-top:0; }
.release-menu  { width:100%; text-align:left; border-radius:5px; box-sizing:border-box; position:relative; }
.release-menu ul { padding:0; }
.release-menu li { width:100%; padding:11px; list-style:none; border-bottom-width:1px; border-bottom-style: solid; cursor:pointer; }
.release-menu li:hover  { width:100%; }

/* Bond Type Selection Section */
.underwriting-assignment-bond-type-selector section {border:0;padding:0;}
.underwriting-assignment-bond-type-selector section > header {display: none;}

.no-section section {
    padding: 0; 
    border: none;
}

.no-section section > header {
    display: none;
}

section.inactive header { 
    padding: 0;
    margin: 0;
    border: none;
}

body .themed.a3 {
    section { 
        background-color:@white; 
        border-color:#ccc; 
    }

    section > header { 
        border-bottom-color: @translucentBorder; 
        color: @charcoal; 
    }

    section.flag {
        border-color: red;
    }

    section.above { 
        background-color: transparent; 
        border-color: transparent; 
    }

    /* color variants */
    section.dark { 
        background-color: @tabGray; 
    }

    .release-menu  { 
        background-color: @white; 
    }

    .release-menu li { 
        border-bottom-color: @translucentBorder; 
    }

    .release-menu li:hover  { 
        background-color: @gray; 
        border-right: 5px solid @orange;
    }

    .release-menu ul li.on { 
        background-color: @gray; 
        border-right: 5px solid @orange; 
    }
}