@import 'a3_variables.less';
@import 'dark_variables.less';

body .themed.dark {
    
    .dropdown-menu .view-selection-button .view-shared-icon {
        color: @dark_gray4;
    }

    .dropdown-menu .view-selection-button .view-shared-icon.active {
        color: @dark_indication4;
    }
}