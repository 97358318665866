@import 'a3_variables.less';
@import 'a3_buttons.less';
@import 'dark_variables.less';

body .themed.dark {
        section { 
                background-color: @dark_secondaryBackgroundColor; 
                border-color: @dark_sectionBorder; 
        }

        section > header { 
                border-bottom-color: @dark_primaryBorderColor; 
                color: rgb(159, 173, 188); 
                font-weight: bold;
        }

        section.flag {
                border-color: @dark_negative;
        }

        section.above { 
                background-color:transparent; 
                border-color:transparent; 
        }

        /* color variants */
        section.dark { 
                background-color: @dark_darkBackground; 
        }

        .release-menu  { 
                background-color: @dark_secondaryBackgroundColor; 
        }

        .release-menu li { 
                border-bottom-color: @dark_translucentBorder; 
        }

        .release-menu li:hover  { 
                background-color: @dark_gray3; 
                border-right: @dark_dropdownBorderRight; 
        }
        
        .release-menu ul li.on { 
                background-color: @dark_gray3; 
                border-right: @dark_dropdownBorderRight; 
        }

        section h5.sub-heading span {
                color: @dark_primaryFontColor;
        }
}