@import 'a3_variables.less';

body { overflow: hidden; }

.scroller { height: 100%; overflow: hidden; position: relative; }

/* main view */
.main-view { width:100%; height:100%; display:flex; }
.pad-main-view { padding-bottom: 40px; padding-top:62px; } // this is toggled depending on the currently active state
.themed { width: 100%; }
.structure-wrapper { height: 100%; }

/* page */
.embedded-title-bar .page-body { 
    padding: 0; 
}

.page { 
    height:100%; 
    margin-left:250px; 
    padding:0px; 
    flex:1; 
    position:relative; 
    position: relative; 
    overflow-y: auto; 
}

.page-body { 
    padding: 20px; 
}

.side-menu-collapsed .page { 
    margin-left: 50px; 
}

.right-pane { 
    margin-left: -350px; 
    margin-right: 350px; 
}

/* page title */
.page-title { 

    padding-left: 20px;
    padding-right: 20px;
    border-width: 1px; 
    border-style: solid;  
    height: 41px;
    display: flex;

    header {
        flex: 1;
        font-weight:bold;
        text-align: left;
        font-size: 14px;
    }

    & > * {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.page-title .field-wrapper {min-height: initial;}
.page-title a { font-size: 12px; line-height: 24px; padding: 0 5px; }
.page-title .nav-divider { color: #d0d3d5; }

.page-title .editable-buttons button { height:24px; font-size:11px; margin-left: 0px; }
.page-body .page-body { padding: 20px; }

.test-selector { width: 300px; margin-top: -3px; margin-bottom: 3px; display: inline-flex; }

.ng-pageslide { padding: 20px; }
.ng-pageslide-body-open > div {width: calc(~'100% - 350px'); }

.embedded-title-bar .page-title { 

    header { 

        p {
            font-weight: bold; 
            margin: 0;
        }

        .dropdown { float: left; }

        .dropdown > a { color: @primaryFontColor; font-weight: bold; font-size: 14px;}

        nav { 
            float: left; 
            font-size: 12px; 
            line-height: 24px; 
            margin: 0 10px; 
            font-weight:normal; 
        }
        
        nav a { 
            padding: 0 5px; 
        }
        
        nav a > i {
            margin-right:5px;
        }
        
        nav .nav-divider { 
            color: @tabGray; 
        }

        .editable-wrap { 
            margin: -7px 0 0 -10px; 
        }
    }

    .refresh-button { 
        margin-right: 5px; 
    }
    
    .refresh-button:hover { 
        text-decoration: none; 
    }
}

.embedded-title-bar .page-title .chart { display: inline-block; padding: 0 15px; position: relative; text-align: left; width: 180px; }
.embedded-title-bar .page-title .chart strong { display: block; font-size: 15px; height: 44px; line-height: 44px; position: absolute; text-align: center; width: 44px; z-index: 100; }
.embedded-title-bar .page-title .chart .chart-wrapper { height: 44px; overflow: hidden; position: absolute; width: 44px; vertical-align: middle; }
.embedded-title-bar .page-title .chart .chart-wrapper > div { height: 86px; left: -20px; position: absolute; top: -20px; width: 86px; }
.embedded-title-bar .page-title .chart p { display: inline-block; font-size: 13px; font-weight: normal; line-height: 1.2em; margin: 0; padding-left: 49px; text-transform: capitalize; vertical-align: middle; }
.embedded-title-bar .page-title .chart .small { text-transform: uppercase; }

.page-body.sticky-page {
    position: absolute;
    top: 41px;
    bottom: 41px;
    padding-right: 3px;
    padding-bottom: 0;
    overflow: hidden;
}


.page-body.sticky-page .scroller {
    padding-right: 17px;
}

.sticky-page-footer {
    position: absolute;
    width: 100%;
    padding: 1rem 2rem;
    bottom: 0rem;
}

.page-body.fill-page {
    position: absolute;
    top: 41px;
    bottom: 0px;
    padding-bottom: 0;
    overflow: hidden;
    width: 100%;
}

.page-body.fill-page .content-scroller {
    height: unset;
}

.block { 
    display: block; 
}

.flex { 
    display: flex; 
}

.flex.gap {
    gap: 20px;
}

.column { 
    flex-direction: column; 
}

.fill { 
    flex: 1; 
}

.flex-column { 
    display: flex;
    flex-direction: column; 
}

.v-center { margin-top: auto !important; margin-bottom: auto !important; }
.space-between { justify-content: space-between; }

/*
  Allow angular.js to be loaded in body, hiding cloaked elements until
  templates compile.  The !important is important given that there may be
  other selectors that are more specific or come later and might alter display.
    https://stackoverflow.com/questions/11249768/angularjs-ng-cloak-ng-show-elements-blink
 */
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

/* responsive */
@media (max-width: 1366px) {
    .right-pane { margin-left: -300px; margin-right: 300px; }
}

@media (max-width: 850px) {
    .main-view { display:block; padding-top:0; }
    .page { height: auto; margin-top:0; margin-left:0; padding-top:0; }
    .right-pane { margin-left: -400px; margin-right: 400px; }
}

/* Activity UI */
.activity-created-info {
  padding: .5rem .5rem 0 .5rem;
  border-top: 1px solid #444;
}

.message-content {
  padding: 10px;
  width: 90%;
  margin-left: 40px;
  margin-top: -10px;
}

.message-metadata {
  margin-top: 10px;
  display: flex;
  width: 100%;
  font-size: 11px;
}

.conversation-message {
  margin-bottom: 20px;
}

.new-message-container {
  display: flex;
  margin-top: 10px;
}

.new-message-container label {
  white-space: nowrap;
  margin-bottom: auto;
}

.new-message.message-content {
  margin-top: 0;
}

body .themed.a3 {
  .page { 
    background-color: @gray; 
  }

  .page-title { 
    border-color: rgba(0,0,0,.15); 
  }

  .ng-pageslide { 
    background: #fff; 
    box-shadow: rgb(204, 204, 204) -1px 2px 5px;
  }
   
  .message-content {
      background-color: #efefef;
      border: 1px solid #ccc;
  }

  .message-medadata {
      color: #ccc;
  }
}