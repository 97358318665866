@import 'a3_variables.less';

body .themed {
    .list .header { 
        width:100%; 
        list-style:none; 
        overflow:auto; 
        zoom:1; 
    }

    .list .header li { 
        padding:10px 20px; 
        float:left; 
    }


    .list .body { 
        width:100%; 
        list-style:none; 
        overflow:auto; 
        zoom:1; 
    }

    .list .body li { 
        padding:10px 20px; 
        float:left; 
    }

    .release-list { 
        padding:0; 
        list-style:none; 
    }

    .release-list li { 
        padding:4px 0; 
    }

    ul.sortable li > i.grab-handle { 
        width: 23px; 
        padding-left: 10px; cursor: move; 
    }

    ul.sortable li > a { 
        padding: 5px 20px 5px 0px; 
        width: ~'calc(100% - 25px)'; 
        display:inline-block; 
    }

    .div-list {
        padding: 20px 10px 20px 10px;
    }
    
    .div-list + .div-list {
        border-top: 1px solid;
    }
    
    .div-list-grab-handle {
        padding: 5px;
        margin-left: -20px;
        width: 20px;
        cursor: move;
        display: none;
    }
    
    .div-list:hover .div-list-grab-handle {
        display: flex;
    }
    
    .themed.a3 {
    
        .div-list + .div-list {
            border-top-color: @gray;
        }    
    
    }
}