@import 'a3_variables.less';
@import 'dark_variables.less';

body .themed.dark {
    /* header */
    .nav-tabs { 
        background-color: @dark_navTabsBackgroundColor; 
        border: 1px solid @dark_navTabsBorderColor; 
        border-left: none;
    }
    
    .nav-tabs li:first-of-type a { 
        border-left: 1px solid @dark_navTabsBorderColor; 
    }
    
    
    .nav-tabs li a:hover { 
        color: @dark_orange; 
    }
    
    .nav-tabs li.active a { 
        color: @dark_positive;
        border-bottom: 5px solid @dark_positive;
    }
    
    .nav-tabs li.active a:focus {
    }
    
    .nav-tabs li.active a:hover {
    }

    .nav-tabs>li>a:hover {
        background:none;        
    }
    
    .nav-tabs>li.active>a, 
    .nav-tabs>li.active>a:hover, 
    .nav-tabs>li.active>a:focus { 
        background-color: transparent;
        border-top: none;
        border-right: none;
        border-left: none;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

    .tab-header .tab-section.on {  
    }

    .tab-content { 
    }
    
    .wizard .tab-content { 
    }
    
    .wizard ul.nav-tabs {
        background-color: transparent; 
        margin-bottom:10px; 
        padding-top:25px; 
    }
    
    .wizard ul.nav-tabs li a { 
        color: #627789; 
    }
    
    .wizard ul.nav-tabs li.active a { 
        color: #627789; 
    }
    
    div.wizard ul.nav-tabs li a::before { 
        background-color: #627789; 
        color:white; 
    }
    
    div.wizard ul.nav-tabs li a::after { 
        background-color: #627789; 
    }
    
    div.wizard ul.nav-tabs li.active ~ li a::after {
        background-color:#F6F7F8;
    }
    
    div.wizard ul.nav-tabs li.active ~ li a::before {
        background-color:#F6F7F8; 
        color:#627789;
    }

    /* main tab container */
    .tab-pane { 
        background: @dark_tabPaneBackgroundColor; 
        padding: 0;
    }

    .tabs.above { 
        background-color:transparent; 
    }

    ul.vertical-tab-nav {
        background: #232931;
    }

    ul.vertical-tab-nav li.active {
        border: none;
    }

    ul.vertical-tab-nav li a {
        background-color: unset;
    }

    ul.vertical-tab-nav li a:hover, 
    ul.vertical-tab-nav li.active a {
        border-right-color: #ABE2AB;
        color: #ABE2AB;
        background-color: transparent;
    }  

    ul.vertical-tab-nav li.heading {
        color: @dark_gray5;
        font-weight: bold;
        border-bottom: 1px solid @dark_gray4;    
    }
}