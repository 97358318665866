@import 'a3_variables.less';
@import 'dark_variables.less';

body .themed.dark {
    
    .div-list {
        border-top: 1px solid @dark_gray3;
        border-bottom: 1px solid @dark_gray3;
    }

    .div-list.alt {
        background-color: @dark_gray3;
    }

    .div-list + .div-list {
        border-top-color: @dark_translucentBorder;
    }
}