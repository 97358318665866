@import 'a3_variables.less';
@import 'silver_variables.less';

.silver-white-button(){
    background:transparent; color: #1b4461; border-color: #a7b8c6;
}

.silver-white-button-hover(){
    background-color: #627789; border-color: #627789; color:#fff;
}

body .themed.silver {
    /* buttons */
    button, a.button,
    input[type=button],
    input[type=submit] { border-color :#627789; color:#fff; background-color:#627789; }

    button:hover, a.button:hover,
    input[type=button]:hover,
    input[type=submit]:hover { color:#fff; background-color:@orange; border-color:@orange; }

    button i, a.button i,
    input[type=button] i,
    input[type=submit] i { }
        
    /* button colors */
    button.gray, a.button.gray, input[type=button].gray, input[type=submit].gray,
    button.white, a.button.white, input[type=button].white, input[type=submit].white { .silver-white-button; }
    button.gray:hover, a.button.gray:hover, input[type=button].gray:hover, input[type=submit].gray:hover,
    button.white:hover, a.button.white:hover, input[type=button].white:hover, input[type=submit].white:hover { .silver-white-button-hover;  }
    
    button.transparent, a.button.transparent, input[type=button].transparent, input[type=submit].transparent { background:transparent; color: #627789; border-color: transparent; }
    button.transparent:hover, a.button.transparent:hover, input[type=button].transparent:hover, input[type=submit].transparent:hover { color:@orange; }

    .btn-group button.active { }
    .btn-group button.inactive { background-color: #29313A }

    /* tag button */
    .tag-button { background-color:@orange; color:#fff; margin-right:10px; padding:5px; border-radius:2px; white-space: nowrap; font-size:11px; }
    .tag-button i.fa-times { margin-left:5px; cursor:pointer; }

    a.tag {color: white; }
    a.tag:hover {color: white; }

    // accordion buttons
    .panel-group .panel .panel-body button {color: #333; background-color: #fff; border-color:#fff; }

    button:disabled:hover, a.button:disabled:hover,
    input[type=button]:disabled:hover,
    input[type=submit]:disabled:hover { background-color: #627789; border-color: #627789; }

    .btn-group  button:first-child, .btn-group  input[type=submit]:first-child, .btn-group  input[type=button]:first-child, .btn-group  a.button:first-child{ border-right: none; }

    .tag-container .tag-form button { background-color: @orange; border-color:@orange; }
    .tag-container .tag button { background-color: @orange; border-color: @orange;}
}
    