@import 'a3_variables.less';

body .themed.silver {
    /* header */
    .nav-tabs { background-color: #f9f9f9; border: 1px solid #ebebeb; border-left:none;}
    .nav-tabs li:first-of-type a { border-left: 1px solid #ebebeb; }
    .nav-tabs li a { color: #1b4461; }
    .nav-tabs li a:hover { color: @orange; background-color:#f9f9f9;}
    .nav-tabs li.active a { background-color: #fff; color:@orange;}
    .nav-tabs li.active a:focus { background-color: #fff;  }
    .nav-tabs li.active a:hover { background-color: #fff; }
    .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {border-top:none; }

    .tab-header .tab-section.on {  background: #fff; }

    .tab-content { border: 1px solid #ebebeb; border-top: none;}
    .wizard .tab-content {border-top:1px solid #ebebeb; }
    .wizard ul.nav-tabs {background-color: transparent; margin-bottom:10px; padding-top:25px; }
    .wizard ul.nav-tabs li a { color: #627789; }
    .wizard ul.nav-tabs li.active a { color: #627789; }
    
    div.wizard ul.nav-tabs li a::before { background-color: #627789; color:white; }
    div.wizard ul.nav-tabs li a::after { background-color: #627789; }
    
    div.wizard ul.nav-tabs li.active ~ li a::after {background-color:#F6F7F8;}
    div.wizard ul.nav-tabs li.active ~ li a::before {background-color:#F6F7F8; color:#627789;}

    /* main tab container */
    .tab-pane { background: #fff; }

    .tabs.above { background-color:transparent; }

    ul.vertical-tab-nav {
        background: white;
    }

    ul.vertical-tab-nav li.active {
        border: 1px solid #ebebeb;
    }

    ul.vertical-tab-nav li a {
        color: #1b4461;
        background-color: #f9f9f9;
    }

    ul.vertical-tab-nav li a:hover {
        border-right-color: #ffa400;
        color: #ffa400;
    }    
 
    ul.vertical-tab-nav li.active a {
        border-right-color: #ffa400;
        color: #ffa400;
        font-weight: bold;
    }    

    ul.vertical-tab-nav li.heading {
        color: #627789;
        font-weight: bold;
        border-bottom: 1px solid #627789;    
    }

    ul.vertical-tab-nav li.heading {
        margin-left: 0px;
        margin-right: 0px;
    }
}