@import 'a3_variables.less';
@import "dark_variables.less";

body .themed.dark {
    body { 
        font-family: @dark_mainFont;
        font-size:@dark_mainFontSize;
    }

    header .small:not(button) { 
        font-size: 11px; 
        font-weight: normal; 
        line-height: 15px; 
        text-transform: uppercase; 
    }

    .green { color:@dark_positive; }
    .orange {color:@dark_orange; }
    .red {color: @dark_warningOrange; }
    .light-gray {color: @dark_gray; }
    .gray {color: @dark_darkGray; }

    .highlightedText { 
        font-weight: bolder; 
    }

    a { 
        color: @dark_linkColor; 
    }
    
    a:hover {
        color:@dark_orange; 
        text-decoration:none; 
    }

    section h1 {}
    section h2 {}
    section h3 {}
    section h4 {}
    section h5 { 
        color: @dark_orange; 
        font-weight: normal; 
    }
    
    section h6 { 
        color:@dark_orange; 
    }

    .badge {
        background-color: @dark_indication5; 
        color: @dark_primaryBadgeFontColor; 
    }
    
    .badge.red {
        background-color: @dark_primaryBadgeBackgroundColor; 
        color: @dark_primaryBadgeFontColor; 
    }

    span.new-badge { 
        background: @dark_newBadgeBackgroundColor; 
        color: @dark_newBadgeFontColor; 
    }
    
    span.fix-badge {  
        background: @dark_fixBadgeBackgroundColor; 
        color: @dark_fixBadgeFontColor; 
    }

    .dropdown .dropdown-menu {
        box-shadow: @dark_dropdownBoxShadow;
        background-color: @dark_dropdownBackgroundColor;
    }
    
    .dropdown .dropdown-menu li:not(.button-row), 
    .dropdown .dropdown-menu li:not(.button-row) a:not(.link), 
    .dropdown .dropdown-menu li:not(.button-row) button,
    .dropdown .dropdown-menu li:not(.button-row) > i, 
    .dropdown .dropdown-menu li:not(.button-row) > i {
        color: @dark_primaryFontColor; 
        background-color: @dark_secondaryBackgroundColor;  
        border-top:none; 
        border-left:none; 
        border-bottom:none; 
        border-color: transparent;
    }

    .dropdown .dropdown-menu li a.btn-link {
        color: @dark_linkColor;
    }
    
    .dropdown .dropdown-menu li:not(.button-row) a:hover, 
    .dropdown .dropdown-menu li:not(.button-row) button:hover {
        color: @dark_orange; 
        border-color: @dark_orange; 
    }
    
    .dropdown .dropdown-menu .divider {
        background-color: @dark_gray4 !important; 
    }

    .document-title { 
        background-color: @dark_secondaryBackgroundColor; 
    }

    .ui-select-highlight {
        font-weight: bold;
        color: @dark_searchHighlightFontColor;
    }

    .alert { 
        border-color: transparent;
        box-shadow: none;
    }

    .alert-warning {
        background: transparent;
        border-color: #8a6d3b;
        color: #fcf8e3;
    }

    .alert-danger {
        border: 2px solid #F88;
        background: @dark_gray1;
        color: unset;
    }
}