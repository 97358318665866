@import 'a3_variables.less';


/* buttons */
button, 
a.button,
input[type=button],
input[type=submit] { 
    padding:6px 20px 8px 20px; 
    font-size:11px; 
    font-weight:normal; 
    line-height:13px; 
    border-width: 1px; 
    border-style:solid; 
    border-radius:2px; 
    outline:0; 
    cursor:pointer; 
    text-decoration:none; 
    display:inline-block;
}

button, 
a,
input[type=button],
input[type=submit] { 
    user-select: none;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: unset;
}

a:focus { outline: none; }

/* button behavior */
button + button { margin-left:10px; }
button.disabled { cursor: default; }

.btn-group button + button { margin-left: 0; }

input[type=button] + input[type=button] { }
input[type=submit] + input[type=submit] { }

//button i:not(.fa-caret-down) { margin-right:10px; font-family:"fontAwesome"; font-style:normal; }
button span + i, button i + span, button i + i, button i + span.ng-hide + span {margin-left:10px;}

/* links */
a { cursor:pointer; }
a + a { user-select: none; }

a[disabled] { 
  pointer-events: none; 
  opacity: .5;
}

/* sizes */
button.mini,    input[type=button].mini,    input[type=submit].mini     { padding: 4px 8px; font-size: 10px; line-height: 9px; border-radius: @smallBorderRadius; }
button.medium,  input[type=button].medium,  input[type=submit].medium   { padding: 7px 12px; height:28px; border-radius: @smallBorderRadius;}
button.small,   input[type=button].small,   input[type=submit].small    { padding: 3px 15px; font-size: 12px; line-height: 1.5; border-radius: @smallBorderRadius; }
button.large,   input[type=button].large,   input[type=submit].large    { padding: 10px 30px; font-size: 16px; line-height: 2; border-radius: @baseBorderRadius;}
button.full,    input[type=button].full,    input[type=submit].full     { width:100%; }

/* tags */
.tag-container { margin: 10px 0; }
.tag-container .tag-form button { padding: 3px 8px 3px 8px; margin: 0 0 0px 5px; vertical-align: text-bottom; }
.tag-container .tag-form button:first-of-type { margin-left: 0; }
.tag-container .tag-form input { height:21px; width:150px; background: white; line-height: 1; }
.tag-container .tag-form .field-wrapper {min-height:21px; margin-top: 0; margin-bottom: 0;}
.tag-container .tag-form { display: flex; }
.tag-container .tag { background: transparent url(../../resources/img/icons/tag.png) 100% 0 no-repeat; color: @charcoal; font-weight: 600; display: inline-block; font-size: 9px; height: 20px; line-height: 21px; margin-right: 5px; padding: 0 12px 0 8px; text-transform: uppercase; }
.tag-container .tag button { padding: 0; box-shadow: none; margin-left: 10px; }
.tag:hover { text-decoration: none; }
.tag-container .tag .tooltip { transform:none; }
.tag-container .ui-helper-hidden-accessible { display: none; }

/* Autocomplete
----------------------------------*/
.ui-autocomplete { position: absolute; cursor: default; }       
//.ui-autocomplete-loading { background: white url('images/ui-anim_basic_16x16.gif') right center no-repeat; }
* html .ui-autocomplete { width:1px; } /* without this, the menu expands to 100% in IE6 */
.ui-menu { border:1px solid #d0d3d5; background:white; list-style:none; padding: 5px; margin: 0; display:block; width:227px; z-index:10000;}
.ui-menu .ui-menu { margin-top: -3px; }
.ui-menu .ui-menu-item { margin:0; padding: 0; width: 200px; }
.ui-menu .ui-menu-item a { text-decoration:none; display:block; color:@charcoal; }
.ui-menu .ui-menu-item a.ui-state-hover, .ui-menu .ui-menu-item a.ui-state-active { margin: -1px; background-color: @charcoal; color: white}


/* remove button */
.button-remove { margin-bottom:20px; }

/* tooltip overrides */
.tooltip-inner { max-width: 210px; white-space: normal; }



/* btn-group */
.btn-group button:first-of-type { border-bottom-right-radius: 0; border-top-right-radius: 0; }
.btn-group button:last-of-type { border-bottom-left-radius: 0; border-top-left-radius: 0; }
.btn-group button { float: left; }
.btn-group button.dropdown-toggle { padding: 12px 7px; }
.btn-group button.dropdown-toggle i { font-size: 13px }

.btn-group button.small { padding: 3px 7px; font-size: 12px; line-height: 1.5; height: 24px; }
.btn-group ~ .btn-group { margin-left:10px; }

.user user-avatar+span { font-weight: bold; line-height: 30px; margin-left: 10px; }
.user-avatar .avatar-img { height: 30px; width: 30px; display: inline-block; float: left; font-size: 25px; text-align: center; line-height: 30px; margin-right: 4px;}

// Disabled
button:disabled , 
a.button:disabled,
input[type=button]:disabled,
input[type=submit]:disabled,
button.disabled, 
a.button.disabled,
input[type=button].disabled,
input[type=submit].disabled 
{ 
  cursor: not-allowed; 
  opacity: 0.6; 
}

.cursor-pointer {cursor:pointer;}

 /* tag button */
 .tag-button { 
    margin-right:10px; 
    padding: 3px 5px;
    border-radius: @baseBorderRadius; 
    white-space: nowrap; 
  }

  .tag-button i.fa-times { 
    margin-left:5px; 
    cursor:pointer; 
  }

.system-account-logo {
    height:30px;
    width:30px;
    padding:3px;
    background-color:white;
    border:1px solid #ccc;
    border-radius: 3px;
}

.large .system-account-logo {
  height: 60px;
  width: 60px;
}


// A3 Style
body .themed.a3 {
    /* buttons */
    button, a.button,
    input[type=button],
    input[type=submit] { border-color:transparent; background:@orange; color:@charcoal; font-weight: 600;; box-shadow: 0px 1px 1px rgba(0,0,0,.18)}

    /* button types/colors */

    button.white { background:@white; color:@charcoal; }
    button.charcoal { background:@charcoal; color:@white; }
    button.gray { background:@gray; color:@charcoal; }
    button.gradient { 
      background:-webkit-linear-gradient(@orange, #dc5f13);
      background:-o-linear-gradient(@orange, #dc5f13);
      background:-moz-linear-gradient(@orange, #dc5f13);
      background:linear-gradient(@orange, #dc5f13); color:@white; }

    button.transparent, a.button.transparent, input[type=button].transparent, input[type=submit].transparent { background:transparent; color:@orange; border-color: transparent; box-shadow:none; }
    button.transparent:hover, a.button.transparent:hover, input[type=button].transparent:hover, input[type=submit].transparent:hover { color:@orange; }

    /* tag button */
    .tag-button { 
      background-color:@orange; 
      color:@white;  
    }

    .btn-group button.active {  }
    .btn-group button.inactive { 
        background: transparent;
        border-color: #d0d3d5;
    }
}