@import 'a3_variables.less';

.query-builder-condition-set {
    width: fit-content;
    box-sizing: border-box;
    position: relative;
}

.query-builder-condition-set .query-builder-condition-set-pointer {
    border-top: 1px dashed @gray;
    position: absolute;
    top: 1rem;
    left: -1rem;
    width: 1rem;
}
        
.query-builder-condition-set .query-builder-condition-container {
    display: flex;
    margin-left: 1rem;
    box-sizing: border-box;
    padding-top: 1rem;
    padding-bottom: 0;
    padding-right: 1rem;
    margin-top: 4px;
}

.query-builder-condition-set .query-builder-condition-container .query-builder-left-line {
    border-left: 1px dashed @gray;
    margin-top: -10px;
    margin-bottom: 15px;
}

.query-builder-condition-set .query-builder-condition-container .query-builder-condition {
    position: relative;
    display: flex;
    height: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    box-sizing: border-box;
    width: calc(100% - 1rem);

    & > span {
        margin-top: auto;
        margin-bottom: auto;
    }

    &:first-of-type {
        margin-top: 0;
    }
}

.query-builder-condition-set .query-builder-condition-container .query-builder-add-buttons {
    position: relative;
    display: flex;
    height: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    box-sizing: border-box;
    width: calc(100% - 1rem);
}

.query-builder-condition-set .query-builder-condition-container .query-builder-condition-pointer {
    border-top: 1px dashed @gray;
    position: absolute;
    top: 1.5rem;
    left: -1rem;
    width: 1rem;
}

.query-builder-condition-set .query-builder-condition-set {
    margin-top: 1rem;
    margin-left: 1rem;
}

.add-condition-button {
    margin-left: .25rem;
    margin-top: .25rem;
}

.query-builder-condition-set .query-builder-plus-button {
    margin-top: 4px;
    padding: 4px;
    font-size: 10px;
    line-height: 9px;
    border-radius: 2px;
}

.query-builder-condition-set .query-builder-condition .property-select-field {
    min-width: 180px;
}

.query-builder-condition-set .query-builder-condition .operator-select-field {
    min-width: 115px;
}

.query-builder-condition-set .query-builder-condition .value-input-field {
    min-width: 100px;
}

body .themed.a3 {
    .query-builder-condition-set .query-builder-condition-set-pointer,
    .query-builder-condition-set .query-builder-condition-container .query-builder-condition-pointer
    {
        border-top-color: @dark_gray;
    }

    .query-builder-condition-set .query-builder-condition-container .query-builder-left-line {
        border-left-color: @dark_gray;
    }
}