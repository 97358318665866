@import 'a3_variables.less';

.modal { 
    overflow-y: auto !important;
 }

.modal-backdrop.in { filter: alpha(opacity=80); opacity: .9; }

.modal-dialog { 
    margin-top: 55px; 
    overflow-y: 'auto'
}

.modal.full-screen .modal-dialog  { 
    margin: 2rem !important;
    height: calc(100% - 4rem) !important;
    width: calc(100% - 4rem) !important;
}

.modal.full-screen .modal-dialog .modal-content {
    height: 100%;
}

.modal.full-height .modal-dialog {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    height: 100%;
    max-height: calc(100% - 4rem) !important;
}

.modal.full-height .modal-dialog .modal-content {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 0;
    padding-top: 0;
}

.modal.modal.full-height .modal-dialog header.modal-header > button {
    right: -20px;
}

.modal.full-height .modal-dialog .modal-content .modal-header {
    position: sticky;
    top: 0;
    border: none;
    min-height: unset;
    z-index: 1000;
    padding-top: 20px;
}

.modal.full-height .modal-dialog .modal-content .modal-footer {
    position: sticky;
    bottom: 0;
    border: none;
    min-height: unset;
    padding-top: 20px;
    margin-top: 0;
    padding-right: 0;
    padding-left: 0;
}
.modal .modal-dialog .scroller.modal-scroller {
    margin-right: -15px;
    padding-right: 15px;
}

//.modal section:not(.text-left):not(.text-right) {text-align: center; }

.modal header.modal-header { text-align: left; padding: 0 20px 10px 0; border-bottom: none; }
.modal header.modal-header > i { margin-right:15px; }
.modal header.modal-header > i.fa-chevron-left { margin-right:5px; }
.modal header.modal-header h1, 
.modal header.modal-header h2,
.modal header.modal-header h3,
.modal header.modal-header h4,
.modal header.modal-header h5,
.modal header.modal-header h6 {margin-top:0;}
.modal header.modal-header > button { background: transparent; box-shadow: none; padding: 10px; position: absolute; right: 0; top: 0; }
.modal header.modal-header > button i { margin: 0; }
.modal header.modal-header > button.modal-refresh-button { right: 25px; padding-right: 5px; }
.modal header.modal-header > button {
    &.modal-refresh-button + button { padding-left: 5px; }
}

.modal footer {padding-top:20px; text-align:right;}
.modal footer button, .modal footer input[type=button], .modal footer input[type=submit] { margin-left:5px;}
.modal iframe { border:none; }
.modal .busy-indicator-backdrop { height: 100%; }

.modal-content {
    padding:20px; 
    min-height: 200px;
}

body .themed.a3 {
    .modal-content { 
        background-color:@gray; 
    }
    
    .modal-backdrop { 
        background-color: @charcoal;
    }

    .modal .modal-dialog .modal-content > header.modal-header button {
        color: #999;
        background: transparent;
        box-shadow: none;
    }

    .modal .modal-dialog .modal-content > header.modal-header button:hover {
        color: @charcoal;
    }
    
    .modal .busy-indicator-backdrop { 
        border-radius: @modalBorderRadius; 
    }

    .modal-content .modal-header.modal-header,
    .modal-content .modal-footer { 
        background-color:@gray; 
    }
}

/*.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog, .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}*/

/* modal sizes */
@media (min-width: 768px){
    .modal-sm { width: 450px;}
}

@media (min-width: 925px){
    .modal-lg { width: 900px;}
}

@media (max-width: 924px){
    .modal-lg { width: 98%; margin: 30px auto; }
}

@media (min-width: 1224px){
    .modal-xl { width: 1200px; }
}

@media (max-width: 1225px){
    .modal-xl { width: 98%; margin: 15px auto; }
}