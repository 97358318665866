@import 'a3_variables.less';

body .themed.silver {
    .modal-content {background-color:#fff; padding:20px; border-radius: 3px;}
    .modal-backdrop { background-color: #627789; }
    .modal header.modal-header > button { margin:4px;color: #ccc; font-size:16px; background:transparent; border-color:transparent }
    .modal header.modal-header > button:hover {color: #627789; background:transparent; border-color:transparent }
    .modal .busy-indicator-backdrop { border-radius: 3px; }
    
    .modal-content .modal-header.modal-header,
    .modal-content .modal-footer {
        background-color:#fff; 
    }
}